import React from "react";
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/Routes';

import styles from './Header.module.scss'
import { MenuButton } from "../MenuButton/MenuButton";

export function Header(): JSX.Element {
    return (
        <div className={styles.HeaderMenu}>
            <div className={styles.Logo}>
                <Link
                    key={'home'}
                    to={{
                        pathname: ROUTES.HOME.END
                    }}
                >
                    Квантовая оптика
                </Link>
            </div>
            <div className={styles.Adress}>
                <ul>
                    <li>
                        +7-812-660-56-26
                    </li>
                    <li>
                        Адрес: г. Санкт-Петербург, ул. Двинская, д. 8, к. 3, стр. 1, помещ. 43Н
                    </li>
                </ul>
            </div>
            <div className={styles.MenuButtonWrapper}>
                <MenuButton
                    label='Продукция'
                    href={'products'}
                />
                <MenuButton
                    label="Публикации"
                    href={'publications'}
                />
                <MenuButton
                    label="Контакты"
                    href={'contacts'}
                />
            </div>
        </div>
    );
}